import _styled from "styled-components";
import { WorkflowRole, assertUnreachable } from '@kindo/universal';
import { useState } from 'react';
import ShareStaticContentConfirmation from './ShareStaticContentConfirmation';
import WorkflowUserPermissionRow from './WorkflowUserPermissionRow';
import { Color, Modal, Select, SelectOption, TextField, TextFieldType, Typography, TypographySize, TypographyWeight, Button, ButtonType } from '~/components/core';
import { CopyButton } from '~/components/shared';
import { BASE_NEXT_URL, getWorkstationRoute } from '~/constants';
import { ToastType, useAppSelector, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
import { Content, isLlmWorkflowStep, WorkflowUserRole } from '~/types';
import { capitalizeFirstLetter } from '~/utils';
const ShareWorkflowModalContainer = _styled.div.withConfig({
  displayName: "ShareWorkflowModal__ShareWorkflowModalContainer",
  componentId: "sc-1gs00wz-0"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
const SectionTitleAndDescription = _styled.div.withConfig({
  displayName: "ShareWorkflowModal__SectionTitleAndDescription",
  componentId: "sc-1gs00wz-1"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0px"
});
const ShareModalAccessTitleAndSelect = _styled.div.withConfig({
  displayName: "ShareWorkflowModal__ShareModalAccessTitleAndSelect",
  componentId: "sc-1gs00wz-2"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const ShareWorkflowUserPermissionsSection = _styled.div.withConfig({
  displayName: "ShareWorkflowModal__ShareWorkflowUserPermissionsSection",
  componentId: "sc-1gs00wz-3"
})({
  "display": "flex",
  "flexDirection": "column"
});
const InviteUserSection = _styled.div.withConfig({
  displayName: "ShareWorkflowModal__InviteUserSection",
  componentId: "sc-1gs00wz-4"
})({
  "display": "flex",
  "gap": "0.5rem"
});
interface ShareWorkflowModalProps {
  onClose: () => void;
  workflowId: string;
}
const getWorkflowAccessLevelDescription = (role: WorkflowRole | null): string => {
  switch (role) {
    case null:
      return 'Only people who are shared can access this agent.';
    case WorkflowRole.EDITOR:
      return 'Anyone can edit this agent.';
    case WorkflowRole.OWNER:
      return 'Anyone can manage and edit this agent.';
    case WorkflowRole.VIEWER:
      return 'Anyone can view and run this agent.';
    default:
      assertUnreachable(role);
      return '';
  }
};
const getWorkflowOrgAccessLevelDescription = (role: WorkflowRole | null): string => {
  switch (role) {
    case null:
      return 'Only people who are shared can access this agent.';
    case WorkflowRole.EDITOR:
      return 'Anyone in the organization can edit this agent.';
    case WorkflowRole.OWNER:
      return 'Anyone in the organization can manage and edit this agent.';
    case WorkflowRole.VIEWER:
      return 'Anyone in the organization can view and run this agent.';
    default:
      assertUnreachable(role);
      return '';
  }
};
const ShareWorkflowModal: React.FC<ShareWorkflowModalProps> = ({
  onClose,
  workflowId
}) => {
  // State
  const [inviteUserInput, setInviteUserInput] = useState<string>('');
  const [inputErrorMessage, setInputErrorMessage] = useState<string>('');

  /**
   * When a user is sharing a workflow with static content,
   * we need to inform them that the contents of the files/data can
   * be exposed, and get their confirmation before sharing.
   *
   * If the callback exists, it will display the confirmation step
   * and call it when the user confirms.
   */
  const [shareStaticContentCallback, setShareStaticContentCallback] = useState<null | (() => void)>(null);

  // Redux
  const {
    userEmail
  } = useAppSelector(state => state.user);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data,
    refetch: refetchWorkflow
  } = nextTrpc.workflows.get.useQuery({
    workflowId
  });
  const workflow = data?.item;

  // Mutations
  const updateWorkflowPublicRoleMutation = nextTrpc.workflows.updatePublicRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated agent public access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update agent public access',
        type: ToastType.ERROR
      });
      console.error('Failed to update agent public access', error);
    }
  });
  const updateWorkflowOrgRoleMutation = nextTrpc.workflows.updateOrgRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated agent org access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update agent org access',
        type: ToastType.ERROR
      });
      console.error('Failed to update agent org access', error);
    }
  });
  const updateWorkflowUserRoleMutation = nextTrpc.workflows.updateUserRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated user agent access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to update user agent access',
        type: ToastType.ERROR
      });
      console.error('Failed to update user agent access', error);
    }
  });
  const removeWorkflowUserRoleMutation = nextTrpc.workflows.removeUserRole.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully removed user agent access',
        type: ToastType.SUCCESS
      });
      await refetchWorkflow();
    },
    onError: error => {
      enqueueToast({
        message: 'Failed to remove user agent access',
        type: ToastType.ERROR
      });
      console.error('Failed to remove user agent access', error);
    }
  });

  // Handlers
  const handleUpdateGeneralAccess = (role: WorkflowRole | null) => {
    setShareStaticContentCallback(null);
    updateWorkflowPublicRoleMutation.mutate({
      workflowId,
      publicRole: role
    });
  };
  const handleUpdateOrgRole = (role: WorkflowRole | null) => {
    setShareStaticContentCallback(null);
    updateWorkflowOrgRoleMutation.mutate({
      workflowId,
      orgRole: role
    });
  };
  const handleUpdateUserPermission = (userRole: WorkflowUserRole, role: WorkflowRole | null) => {
    setShareStaticContentCallback(null);

    // Remove user if role is null
    if (role === null) {
      removeWorkflowUserRoleMutation.mutate({
        workflowId,
        userId: userRole.userId
      });
      return;
    }

    // Update user role
    updateWorkflowUserRoleMutation.mutate({
      workflowId,
      email: userRole.userEmail,
      workflowRole: role
    });
  };
  const handleAddUser = async () => {
    setShareStaticContentCallback(null);
    if (!inviteUserInput) {
      setInputErrorMessage('Please enter an email.');
      return;
    }
    try {
      await updateWorkflowUserRoleMutation.mutateAsync({
        workflowId,
        email: inviteUserInput,
        workflowRole: WorkflowRole.VIEWER
      });
      setInviteUserInput('');
      setInputErrorMessage('');
    } catch (error: any) {
      const errorMessage = error.data.kindoErrorMessage || 'Failed to add user to agent.';
      console.error(errorMessage, error);
      setInputErrorMessage(errorMessage);
    }
  };
  const renderSectionTitle = (title: string) => <Typography color={Color.NAVY} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
      {title}
    </Typography>;
  const workflowStepStaticContent: Content[] = workflow?.steps?.filter(isLlmWorkflowStep).flatMap(step => step.staticContent) ?? [];
  const shouldShowConfirmShareStaticContentStage = !workflow?.publicRole && !!workflowStepStaticContent.length;

  // TODO: Render something different if user cannot share

  const baseRoleOptions: SelectOption<WorkflowRole | null>[] = [WorkflowRole.VIEWER, WorkflowRole.EDITOR, WorkflowRole.OWNER].map(role => ({
    label: capitalizeFirstLetter(role.toLowerCase()),
    value: role
  }));
  const accessRoleOptions: SelectOption<WorkflowRole | null>[] = [{
    label: 'None',
    value: null
  }, ...baseRoleOptions];
  const userRoleOptions: SelectOption<WorkflowRole | null>[] = [...baseRoleOptions, {
    label: 'Remove',
    value: null,
    destructive: true
  }];
  return <Modal onClose={onClose} open>
      <ShareWorkflowModalContainer>
        {!!shareStaticContentCallback && <ShareStaticContentConfirmation onClose={() => setShareStaticContentCallback(null)} onConfirm={shareStaticContentCallback} staticContent={workflowStepStaticContent} />}
        {!shareStaticContentCallback && <>
            {/* Title */}
            <Typography color={Color.NAVY} size={TypographySize.H3}>
              Share Agent
              {workflow?.displayName ? ` "${workflow.displayName}"` : ''}
            </Typography>
            {/* Invite users input */}
            <InviteUserSection>
              <TextField error={!!inputErrorMessage} errorMessage={inputErrorMessage} fullWidth onChange={value => {
            setInputErrorMessage('');
            setInviteUserInput(value);
          }} onEnter={shouldShowConfirmShareStaticContentStage ? () => setShareStaticContentCallback(() => handleAddUser) : handleAddUser} placeholder="Enter email of a Kindo user to share" type={TextFieldType.OUTLINED} value={inviteUserInput} />
              <Button label="Share" onClick={shouldShowConfirmShareStaticContentStage ? () => setShareStaticContentCallback(() => handleAddUser) : handleAddUser} type={ButtonType.FILLED} />
            </InviteUserSection>
            {/* User Permissions */}
            <ShareWorkflowUserPermissionsSection>
              <SectionTitleAndDescription>
                {renderSectionTitle('User Permissions')}
                <Typography color={Color.GRAY} size={TypographySize.SMALL}>
                  {workflow?.userRoles.length === 0 ? 'This agent has not been shared with any users.' : 'Users this agent has been shared with.'}
                </Typography>
              </SectionTitleAndDescription>
              {/* List of shared users */}
              {workflow?.userRoles.map(userRole => <WorkflowUserPermissionRow key={userRole.userId} disabled={userRole.userEmail === userEmail} onChange={newRole => handleUpdateUserPermission(userRole, newRole)} options={userRoleOptions} permission={userRole.role} userEmail={userRole.userEmail} userName={userRole.userName} />)}
            </ShareWorkflowUserPermissionsSection>
            {/* Org Access */}
            {workflow && <SectionTitleAndDescription>
                <ShareModalAccessTitleAndSelect>
                  {renderSectionTitle('Organization Access')}
                  <Select<WorkflowRole | null> data-id="workflow_access_status" onChange={value => shouldShowConfirmShareStaticContentStage ? setShareStaticContentCallback(() => () => handleUpdateOrgRole(value)) : handleUpdateOrgRole(value)} options={accessRoleOptions} placeholderLabel="Select Access Level" value={workflow.orgRole} />
                </ShareModalAccessTitleAndSelect>
                <Typography color={Color.GRAY} size={TypographySize.SMALL}>
                  {getWorkflowOrgAccessLevelDescription(workflow.orgRole)}
                </Typography>
              </SectionTitleAndDescription>}
            {/* Public Access */}
            {workflow && <SectionTitleAndDescription>
                <ShareModalAccessTitleAndSelect>
                  {renderSectionTitle('Public Access')}
                  <Select<WorkflowRole | null> data-id="workflow_access_status" onChange={value => shouldShowConfirmShareStaticContentStage ? setShareStaticContentCallback(() => () => handleUpdateGeneralAccess(value)) : handleUpdateGeneralAccess(value)} options={accessRoleOptions} placeholderLabel="Select Access Level" value={workflow.publicRole} />
                </ShareModalAccessTitleAndSelect>
                <Typography color={Color.GRAY} size={TypographySize.SMALL}>
                  {getWorkflowAccessLevelDescription(workflow.publicRole)}
                </Typography>
              </SectionTitleAndDescription>}
            <CopyButton data-id="workflow_link_copy" label="Copy Agent Link" text={BASE_NEXT_URL + getWorkstationRoute({
          workflowId
        })} tooltip="Share links only work for users who already have access to this agent via the above controls" />
          </>}
      </ShareWorkflowModalContainer>
    </Modal>;
};
export default ShareWorkflowModal;