import _styled from "styled-components";
import Papa from 'papaparse';
import { useState, useEffect } from 'react';
// import { useState, useEffect } from 'react';

import CsvTable, { CsvRow } from './CsvTable';
import { ChatMessageContentReference } from '~/components/Chat';
import { getContentProxyFetchUrl } from '~/constants';
const CsvViewerContainer = _styled.div.withConfig({
  displayName: "CsvViewer__CsvViewerContainer",
  componentId: "sc-1dylmnq-0"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.5rem"
});
interface CsvViewerProps {
  contentReference: ChatMessageContentReference;
  // Use to specify which columns if any should have tooltip enabled
  columnsWithTooltip?: string[];
}
const CsvViewer = ({
  contentReference,
  columnsWithTooltip = []
}: CsvViewerProps) => {
  const contentUrl = getContentProxyFetchUrl(contentReference.contentId);
  const [data, setData] = useState<CsvRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fetchAndParseCSV = async (fileUrl: string): Promise<CsvRow[]> => await new Promise((resolve, reject) => {
    Papa.parse(fileUrl, {
      skipEmptyLines: true,
      download: true,
      header: true,
      complete: results => {
        resolve(results.data as CsvRow[]);
      },
      error: err => {
        reject(err);
      }
    });
  });

  // set data based on file type
  useEffect(() => {
    const downloadCSV = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fileUrl = contentUrl;
        const csvData = await fetchAndParseCSV(fileUrl);
        setData(csvData);
      } catch (err) {
        setError('Failed to download and parse CSV');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    downloadCSV().catch(err => {
      console.error('Error downloading CSV:', err);
    });
  }, [contentUrl, contentReference]);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return <CsvViewerContainer>
      <CsvTable columnsWithTooltip={columnsWithTooltip} rows={data} />
    </CsvViewerContainer>;
};
export default CsvViewer;