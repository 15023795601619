import _styled from "styled-components";
import React, { useState } from 'react';
import { ContentData } from '../../../Files.types';
import { StaticFilePreview } from './StaticFilePreview';
import { Color, Icon, Icons, Typography } from '~/components/core';
import { TypographyWrap } from '~/components/core/Typography';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { ContentIcon } from '~/components/shared';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useFileDropdownItems, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const FileCardContainer = _styled.div.withConfig({
  displayName: "FileCard__FileCardContainer",
  componentId: "sc-1pbtvtx-0"
})({
  "position": "relative",
  "display": "flex",
  "minWidth": "430px",
  "flexDirection": "row-reverse",
  "borderRadius": "0.75rem",
  "&:hover": {
    "backgroundImage": "linear-gradient(to bottom, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#8CAEBA var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#4D7280 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)"
  }
});
const FileCardContent = _styled.div.withConfig({
  displayName: "FileCard__FileCardContent",
  componentId: "sc-1pbtvtx-1"
})({
  "display": "flex",
  "maxHeight": "320px",
  "width": "440px",
  "flexDirection": "column",
  "gap": "0.75rem",
  "borderRadius": "0.75rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))",
  "padding": "1rem",
  "paddingLeft": "1.25rem"
});
const Gradient = _styled.div.withConfig({
  displayName: "FileCard__Gradient",
  componentId: "sc-1pbtvtx-2"
})({
  "position": "absolute",
  "left": "5%",
  "top": "0px",
  "display": "none",
  "maxHeight": "10px",
  "width": "90%",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#C3C1F8 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#FFA797 var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
  "padding": "0.25rem",
  ".group:hover &": {
    "display": "block"
  }
});
const FileCardHeader = _styled.div.withConfig({
  displayName: "FileCard__FileCardHeader",
  componentId: "sc-1pbtvtx-3"
})({
  "display": "flex",
  "justifyContent": "space-between"
});
const FileCardIconAndName = _styled.div.withConfig({
  displayName: "FileCard__FileCardIconAndName",
  componentId: "sc-1pbtvtx-4"
})({
  "display": "flex",
  "maxWidth": "80%",
  "alignItems": "center",
  "gap": "0.5rem"
});
type FileCardProps = {
  file: ContentData;
};
const FileCard: React.FC<FileCardProps> = ({
  file
}: FileCardProps) => {
  const {
    getFileDropdownItems
  } = useFileDropdownItems();
  const {
    enqueueToast
  } = useToast();
  const [contentToRemove, setContentToRemove] = useState<ContentData | null>(null);
  const removeContentMutation = nextTrpc.content.remove.useMutation({
    onSuccess: () => {
      setContentToRemove(null);
      enqueueToast({
        message: 'File removed successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: err => {
      console.error(err);
      enqueueToast({
        message: 'Failed to remove file',
        type: ToastType.ERROR
      });
    }
  });
  const handleContentRemove = (contentId: string) => removeContentMutation.mutate({
    id: contentId
  });

  // TODO: Try to move the shared logic in this and FilesTab/Files.tsx
  // to a hook and/or shared confirmation modal
  const dropdownItems = getFileDropdownItems({
    contentId: file.id,
    onContentRemove: () => setContentToRemove(file)
  });
  return <>
      <FileCardContainer className="group">
        <Gradient />
        <FileCardContent className="group">
          <FileCardHeader>
            <FileCardIconAndName>
              <ContentIcon contentSource={file.source} />
              <Typography wrap={TypographyWrap.NO_WRAP}>{file.name}</Typography>
            </FileCardIconAndName>
            <Dropdown trigger={<Icons color={Color.NAVY} icon={Icon.MORE} />}>
              {dropdownItems.map(option => <DropdownItem key={option.title} destructive={option.destructive} icon={option.icon} onClick={option.onClick} title={option.title} />)}
            </Dropdown>
          </FileCardHeader>
          <div>
            <StaticFilePreview fileId={file.id} fileType={file.fileType} />
          </div>
        </FileCardContent>
      </FileCardContainer>
      {contentToRemove && <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={removeContentMutation.isLoading} header={contentToRemove?.name ? `Are you sure you want to remove ${contentToRemove.name}?` : 'Are you sure you want to remove this file?'} onCancel={() => {
      setContentToRemove(null);
    }} onConfirm={() => handleContentRemove(contentToRemove.id)} open subtext="This action cannot be undone." />}
    </>;
};
export default FileCard;