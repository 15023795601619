import _styled from "styled-components";
import { ChatAccessLevel, assertUnreachable } from '@kindo/universal';
import { CopyButton } from '../shared';
import { Color, Modal, Select, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { BASE_NEXT_URL, getWorkstationRoute } from '~/constants';
import { ToastType, useAppSelector, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const ShareChatModalContainer = _styled.div.withConfig({
  displayName: "ShareChatModal__ShareChatModalContainer",
  componentId: "sc-2dyiag-0"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
const SectionTitleAndDescription = _styled.div.withConfig({
  displayName: "ShareChatModal__SectionTitleAndDescription",
  componentId: "sc-2dyiag-1"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0px"
});
const ShareModalGeneralAccessTitleAndSelect = _styled.div.withConfig({
  displayName: "ShareChatModal__ShareModalGeneralAccessTitleAndSelect",
  componentId: "sc-2dyiag-2"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
interface ShareChatModalProps {
  chatId: string;
  onClose: () => void;
}
const getChatOrgAccessLevelDisplayName = (accessLevel: ChatAccessLevel): string => {
  switch (accessLevel) {
    case ChatAccessLevel.RESTRICTED:
      return 'None';
    case ChatAccessLevel.EDIT:
      return 'Editor';
    case ChatAccessLevel.VIEW:
      return 'Viewer';
    default:
      assertUnreachable(accessLevel);
      return '';
  }
};
const getChatOrgAccessLevelDescription = (accessLevel: ChatAccessLevel): string => {
  switch (accessLevel) {
    case ChatAccessLevel.RESTRICTED:
      return 'No organization access has been set for this chat.';
    case ChatAccessLevel.EDIT:
      return 'Anyone in the organization can edit this chat.';
    case ChatAccessLevel.VIEW:
      return 'Anyone in the organization can view this chat.';
    default:
      assertUnreachable(accessLevel);
      return '';
  }
};
const ShareChatModal: React.FC<ShareChatModalProps> = ({
  onClose,
  chatId
}) => {
  // Custom hooks
  const {
    enqueueToast
  } = useToast();

  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Queries
  const {
    data: chat,
    refetch: refetchChat
  } = nextTrpc.chats.get.useQuery({
    chatId
  });
  const orgAccessLevel = chat?.item?.orgAccess as ChatAccessLevel ?? ChatAccessLevel.RESTRICTED;
  const updateChatOrgAccessMutation = nextTrpc.chats.updateOrgAccess.useMutation({
    onSuccess: async () => {
      enqueueToast({
        message: 'Successfully updated chat access',
        type: ToastType.SUCCESS
      });
      await refetchChat();
    }
  });

  // Handlers
  const handleUpdateOrgAccess = async (accessLevel: ChatAccessLevel) => {
    try {
      await updateChatOrgAccessMutation.mutateAsync({
        chatId,
        orgAccess: accessLevel
      });
    } catch (error) {
      enqueueToast({
        message: 'Failed to update chat access',
        type: ToastType.ERROR
      });
      console.error('Failed to update chat access', error);
    }
  };

  // Constants
  const isChatCreator = chat?.item?.creatorId === userId;
  const renderSectionTitle = (title: string) => <Typography color={Color.NAVY} size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
      {title}
    </Typography>;
  return <Modal onClose={onClose} open>
      <ShareChatModalContainer>
        <>
          {/* Title */}
          <Typography color={Color.NAVY} size={TypographySize.H3}>
            Share Chat{chat?.item?.title ? ` "${chat?.item?.title}"` : ''}
          </Typography>
          {/* Org Access */}
          <SectionTitleAndDescription>
            <ShareModalGeneralAccessTitleAndSelect>
              {renderSectionTitle('Organization Access')}
              <Select data-id="chat_access_status" disabled={!isChatCreator} onChange={value => handleUpdateOrgAccess(value as ChatAccessLevel)} options={[ChatAccessLevel.RESTRICTED, ChatAccessLevel.VIEW, ChatAccessLevel.EDIT].map(accessLevel => ({
              label: getChatOrgAccessLevelDisplayName(accessLevel),
              value: accessLevel
            }))} placeholderLabel="Select Access Level" value={orgAccessLevel} />
            </ShareModalGeneralAccessTitleAndSelect>
            <Typography color={Color.GRAY} size={TypographySize.SMALL}>
              {getChatOrgAccessLevelDescription(orgAccessLevel)}
            </Typography>
          </SectionTitleAndDescription>
          {orgAccessLevel !== ChatAccessLevel.RESTRICTED && <CopyButton data-id="chat_link_copy" label="Copy Chat Link" text={BASE_NEXT_URL + getWorkstationRoute({
          chatId
        })} tooltip="Share links only work for users who already have access to this agent via the above controls" />}
        </>
      </ShareChatModalContainer>
    </Modal>;
};
export default ShareChatModal;