import { Icon } from '../../../core';
import { ContentSource } from './Files.types';
export const getContentIntegrationIcon = (integration: ContentSource): Icon => {
  switch (integration) {
    case ContentSource.DROPBOX:
      return Icon.DROPBOX;
    case ContentSource.GOOGLE_DRIVE:
      return Icon.GOOGLE_DRIVE;
    case ContentSource.ZENDESK:
      return Icon.ZENDESK;
    case ContentSource.WEB:
      return Icon.SEARCH;
    // TODO: Use a dedicated icon for different artifact results
    case ContentSource.LIBRARY:
    default:
      return Icon.FILE;
  }
};