import { FileType, Integration } from '@kindo/universal';

// TODO: Move IntegrationType to packages and use it in the backend
export enum ContentSource {
  BOX = Integration.BOX,
  DROPBOX = Integration.DROPBOX,
  GOOGLE_DRIVE = Integration.GOOGLE_DRIVE,
  LIBRARY = 'Library',
  ONEDRIVE = Integration.ONEDRIVE,
  WEB = 'Web',
  ZENDESK = Integration.ZENDESK,
}
export type FilesData = {
  allFiles: ContentData[];
  myFiles: ContentData[];
  recentFiles: ContentData[];
};
export type ContentData = {
  createdAt: string;
  fileType: FileType | undefined;
  id: string;
  name: string;
  source: ContentSource;
  uploadedBy: string;
};