import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useToast, { ToastType } from './useToast';

import { VERSION_ROUTE } from '~/constants';

// NEXT_PUBLIC_ env vars won't be exposed to the client when destructuring is used.
// eslint-disable-next-line prefer-destructuring
const NEXT_PUBLIC_BUILD_TIMESTAMP = process.env.NEXT_PUBLIC_BUILD_TIMESTAMP;

const useCheckClientVersionOutdated = () => {
  // State
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    NEXT_PUBLIC_BUILD_TIMESTAMP
  );
  // To prevent multiple toasts when multiple new versions are released, only show one.
  const [isToastShown, setIsToastShown] = useState(false);

  // Hooks
  const { enqueueToast } = useToast();
  const router = useRouter();

  // https://github.com/vercel/next.js/issues/5652#issuecomment-906925942
  useEffect(() => {
    const interval = setInterval(
      async () => {
        try {
          const res = await fetch(VERSION_ROUTE);
          const data = await res.json();
          if (data.version !== currentVersion && !isToastShown) {
            // The toast will show until the page is changed. User cannot x out.
            // Only one will show at a time
            enqueueToast({
              type: ToastType.REFRESH,
              message: 'Refresh for latest version',
              autoClose: false,
              preventClose: true,
              onClick: () => router.reload()
            });
            setCurrentVersion(data.version);
            setIsToastShown(true);
          }
        } catch (error) {
          console.error('Failed to fetch version:', error);
        }
      },
      2 * 60 * 1000 // Check every 2 minutes
    );
    return () => clearInterval(interval);
  });

  return currentVersion;
};

export default useCheckClientVersionOutdated;
