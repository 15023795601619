import { Color, Icon, Icons, Size } from '~/components/core';
import { ContentSource, getContentIntegrationIcon } from '~/components/Library/components/FilesTab';
interface ContentIconProps {
  contentSource: ContentSource;
  size?: Size;
}
const ContentIcon = ({
  contentSource,
  size
}: ContentIconProps) => {
  const icon = getContentIntegrationIcon(contentSource);

  // Integration icons are smaller since they often sit in a container
  const defaultSize = icon === Icon.FILE ? Size.LARGE : Size.X_LARGE;
  return <Icons color={icon === Icon.FILE ? Color.NAVY : undefined} icon={icon} size={size ?? defaultSize} />;
};
export default ContentIcon;