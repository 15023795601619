import _styled from "styled-components";
import { useState } from 'react';
import { Pagination } from '~/components/Chat/AddFileModal/Pagination';
import { BORDER_COLORS, Color, Table, TableColumn, TableRow } from '~/components/core';
import { TypographyWrap } from '~/components/core/Typography';
const ROWS_PER_PAGE = 5;
const CsvTableContainer = _styled.div.withConfig({
  displayName: "CsvTable__CsvTableContainer",
  componentId: "sc-1h22l8u-0"
})({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "overflow": "auto",
  "borderRadius": "0.375rem",
  "borderWidth": "1px",
  "--tw-drop-shadow": "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});
const CsvDataContainer = _styled.div.withConfig({
  displayName: "CsvTable__CsvDataContainer",
  componentId: "sc-1h22l8u-1"
})({
  "flexGrow": "1",
  "overflow": "auto"
});
const PaginationContainer = _styled.div.withConfig({
  displayName: "CsvTable__PaginationContainer",
  componentId: "sc-1h22l8u-2"
})(["", " ", ""], {
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "borderBottomRightRadius": "0.5rem",
  "borderBottomLeftRadius": "0.5rem",
  "borderTopWidth": "1px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 247 / var(--tw-bg-opacity))",
  "padding": "0.5rem"
}, BORDER_COLORS[Color.GRAY_LIGHT]);

// Object that maps column name to cell value, e.g. { 'column1': 'value1', 'column2': 'value2' }
export type CsvRow = Record<string, string>;
interface CsvTableProps {
  rows: CsvRow[];
  columnsWithTooltip?: string[];
}
const CsvTable: React.FC<CsvTableProps> = ({
  rows,
  columnsWithTooltip = []
}: CsvTableProps) => {
  const [page, setPage] = useState(0);
  const columnNames = rows[0] ? Object.keys(rows[0]) : [];
  const columns: TableColumn<string>[] = [{
    title: 'Row #'
  }, ...columnNames.map((col): TableColumn => ({
    title: col,
    width: 400,
    wrap: TypographyWrap.NO_WRAP,
    tooltipEnabled: columnsWithTooltip.includes(col)
  }))];
  const csvRows: TableRow[] = rows.map((row, index) => ({
    key: `row-${index + 1}`,
    textColor: Color.GRAY,
    cells: [index + 1, ...Object.values(row)]
  }));
  const start = page * ROWS_PER_PAGE;
  const pageRows = csvRows.slice(start, start + ROWS_PER_PAGE);
  const emptyRows: TableRow[] = Array.from({
    length: ROWS_PER_PAGE - pageRows.length
  }, (_, index) => ({
    key: `empty-${start + pageRows.length + index}`,
    textColor: Color.GRAY,
    cells: Array(columns.length).fill('')
  }));
  const displayedRows = [...pageRows, ...emptyRows];
  return <CsvTableContainer>
      <CsvDataContainer>
        <Table<string, string> borderColor={Color.GRAY_LIGHT} columns={columns} noRowsText="No data found" rows={displayedRows} showColumnHeaderBorder />
      </CsvDataContainer>
      <PaginationContainer>
        <Pagination initialPage={page} onPageActive={() => {}} onPageChange={setPage} totalPages={Math.ceil(csvRows.length / ROWS_PER_PAGE)} />
      </PaginationContainer>
    </CsvTableContainer>;
};
export default CsvTable;