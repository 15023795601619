import _styled from "styled-components";
import { LLM_DISPLAY_NAMES, PROVIDER_DISPLAY_NAMES } from '@kindo/universal';
import { useState } from 'react';
import { OrgModelsDlpFiltersConfig } from '../../DlpFilters/OrgModelsDlpFiltersConfig';
import { OrgProvidersDlpFiltersConfig } from '../../DlpFilters/OrgProvidersDlpFiltersConfig';
import { Color, Icon, IconButton, IconButtonType, Modal, ModalWidth, Typography, TypographySize } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { ModelOrProvider } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
const HeightContainer = _styled.div.withConfig({
  displayName: "DlpFiltersConfigModal__HeightContainer",
  componentId: "sc-rjcki6-0"
})({
  "maxHeight": "70vh",
  "overflowY": "scroll"
});
const ContentContainer = _styled.div.withConfig({
  displayName: "DlpFiltersConfigModal__ContentContainer",
  componentId: "sc-rjcki6-1"
})({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem"
});
const Header = _styled.div.withConfig({
  displayName: "DlpFiltersConfigModal__Header",
  componentId: "sc-rjcki6-2"
})({
  "display": "flex",
  "gap": "1rem"
});
interface DlpFiltersConfigModalProps {
  canAccessSecuritySettings: boolean;
  modelOrProvider: ModelOrProvider;
  onClose: () => void;
}
const DlpFiltersConfigModal: React.FC<DlpFiltersConfigModalProps> = ({
  canAccessSecuritySettings,
  modelOrProvider,
  onClose
}) => {
  const {
    model,
    provider
  } = modelOrProvider;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const {
    enqueueToast
  } = useToast();
  const nextTrpcContext = nextTrpc.useContext();

  // Mutation
  const deleteOrgProviderDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.deleteOrgProviderDlpFiltersConfig.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Custom configuration deleted',
        type: ToastType.SUCCESS
      });
      onClose();
      void nextTrpcContext.adminSecurityConfig.listOrgProviderSecurityConfigs.invalidate();
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to delete custom configuration',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setShowDeleteConfirmation(false);
    }
  });
  const deleteOrgModelDlpFiltersConfigMutation = nextTrpc.dlpFiltersConfig.deleteOrgModelDlpFiltersConfig.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Custom configuration deleted',
        type: ToastType.SUCCESS
      });
      onClose();
      void nextTrpcContext.adminSecurityConfig.listOrgModelSecurityConfigs.invalidate();
    },
    onError: error => {
      console.error(error);
      enqueueToast({
        message: 'Failed to delete custom configuration',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setShowDeleteConfirmation(false);
    }
  });
  const handleDelete = () => {
    if (provider) {
      deleteOrgProviderDlpFiltersConfigMutation.mutate({
        provider
      });
    } else {
      deleteOrgModelDlpFiltersConfigMutation.mutate({
        model
      });
    }
  };
  const displayName = model ? LLM_DISPLAY_NAMES[model] : PROVIDER_DISPLAY_NAMES[provider];
  return <>
      <Modal onClose={onClose} open width={ModalWidth['3XL']}>
        <HeightContainer>
          <ContentContainer>
            <Header>
              <Typography color={Color.NAVY} size={TypographySize.H2}>
                {displayName} Custom Configuration
              </Typography>
              <IconButton color={Color.RED} icon={Icon.TRASH} onClick={() => setShowDeleteConfirmation(true)} type={IconButtonType.OUTLINED} />
            </Header>
            {provider ? <OrgProvidersDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} provider={provider} /> : <OrgModelsDlpFiltersConfig canAccessSecuritySettings={canAccessSecuritySettings} model={model} />}
          </ContentContainer>
        </HeightContainer>
      </Modal>
      {showDeleteConfirmation && <ConfirmationModal confirmButtonLabel="Delete" confirmLoading={deleteOrgProviderDlpFiltersConfigMutation.isLoading || deleteOrgModelDlpFiltersConfigMutation.isLoading} header={`Delete ${displayName} Custom Configuration?`} onCancel={() => setShowDeleteConfirmation(false)} onConfirm={handleDelete} open subtext="This action cannot be undone." />}
    </>;
};
export default DlpFiltersConfigModal;