import _styled from "styled-components";
import { Button, ButtonType, Color, Icon, Icons, Typography, TypographySize } from '~/components/core';
import { Content } from '~/types';
const StaticContentItems = _styled.div.withConfig({
  displayName: "ShareStaticContentConfirmation__StaticContentItems",
  componentId: "sc-pchkt3-0"
})({
  "display": "grid",
  "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
});
const ContentIconAndName = _styled.div.withConfig({
  displayName: "ShareStaticContentConfirmation__ContentIconAndName",
  componentId: "sc-pchkt3-1"
})({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.25rem"
});
const ButtonsContainer = _styled.div.withConfig({
  displayName: "ShareStaticContentConfirmation__ButtonsContainer",
  componentId: "sc-pchkt3-2"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-end",
  "gap": "0.5rem"
});
interface ShareStaticContentConfirmationProps {
  onClose: () => void;
  onConfirm: () => void;
  staticContent: Pick<Content, 'fileName'>[];
}
const ShareStaticContentConfirmation: React.FC<ShareStaticContentConfirmationProps> = ({
  staticContent,
  onClose,
  onConfirm
}) => <>
    <Typography color={Color.NAVY} size={TypographySize.H3}>
      Share agent with included files
    </Typography>
    <Typography color={Color.GRAY}>
      The agent you are about to share includes the following files:
    </Typography>
    <StaticContentItems>
      {staticContent.map(content => <ContentIconAndName key={content.fileName}>
          <Icons color={Color.NAVY} icon={Icon.FILE} />
          <Typography color={Color.NAVY}>{content.fileName}</Typography>
        </ContentIconAndName>)}
    </StaticContentItems>
    <Typography color={Color.GRAY}>
      It is possible that the contents of these files could be exposed to a
      runner of this agent.
    </Typography>
    <Typography color={Color.GRAY}>
      Would you like to continue sharing this agent?
    </Typography>
    <ButtonsContainer>
      <Button label="Cancel" onClick={onClose} type={ButtonType.OUTLINED} />
      <Button label="Share" onClick={onConfirm} type={ButtonType.FILLED} />
    </ButtonsContainer>
  </>;
export default ShareStaticContentConfirmation;