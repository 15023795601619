import { nextTrpc } from '~/trpc';

const useGetOrgCredits = (): { orgCredits: number | undefined } => {
  const { data: getOrgRemainingCreditsData } =
    nextTrpc.credits.getOrgRemainingCredits.useQuery();

  // Show zero instead of negative balances
  const orgRemainingCredits = getOrgRemainingCreditsData?.orgRemainingCredits
    ? Math.max(getOrgRemainingCreditsData.orgRemainingCredits, 0)
    : undefined;

  return { orgCredits: orgRemainingCredits };
};

export default useGetOrgCredits;
