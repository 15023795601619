import React, { useState } from 'react';
import { Button, ButtonType, Color, Icon, Size } from '~/components/core';
type CopyButtonProps = {
  text: string;
  buttonType?: ButtonType;
  'data-id'?: string | undefined;
  icon?: Icon;
  label?: string;
  showSuccessTime?: number;
  size?: Size;
  successText?: string;
  textColor?: Color | undefined;
  tooltip?: string;
};
const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  'data-id': dataId,
  label = 'Copy',
  icon = Icon.COPY,
  tooltip,
  size = Size.MEDIUM,
  textColor,
  showSuccessTime = 1000,
  successText = 'Copied',
  buttonType = ButtonType.OUTLINED
}) => {
  const [copied, setCopied] = useState(false);
  const copyText = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, showSuccessTime);
  };
  return <Button data-id={dataId} label={copied ? successText : label} onClick={copyText} size={size} startIcon={copied ? Icon.CHECK : icon} textColor={textColor} tooltip={tooltip} type={buttonType} />;
};
export default CopyButton;