import _styled from "styled-components";
import React from 'react';
import { NavButton } from './NavButton';
import { ProfileButton } from './ProfileButton';
import { Icon, Icons, Color } from '~/components/core';
import { LIBRARY_ROUTE, PLUGINS_ROUTE, ROOT_ROUTE, SECURITY_SETTINGS_ROUTE, SETTINGS_ROUTE, SUPPORT_ROUTE, WORKFLOW_BUILDER_ROUTE } from '~/constants';
const NavContainer = _styled.nav.withConfig({
  displayName: "Nav__NavContainer",
  componentId: "sc-1x6rrh1-0"
})({
  "position": "relative",
  "display": "flex",
  "height": "100%",
  "width": "72px",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-between",
  "backgroundColor": "#095A7C0D",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
});
const NavButtonGroup = _styled.div.withConfig({
  displayName: "Nav__NavButtonGroup",
  componentId: "sc-1x6rrh1-1"
})({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "gap": "1.5rem"
});
const Nav = () => <NavContainer>
    <NavButtonGroup>
      {/* New Chat (Home) */}
      <Icons color={Color.NAVY} icon={Icon.KINDO_LOGO} invert={process.env.NODE_ENV !== 'production'} size={56} />

      <NavButton data-id="nav_new_chat" hideActive icon={Icon.CHAT} route={ROOT_ROUTE} title="New Chat" />
      {/* Workflow Builder */}
      <NavButton data-id="nav_workflow_builder" icon={Icon.BOLT} route={WORKFLOW_BUILDER_ROUTE} title="Create Agent" />
      {/* TODO: Refer a friend */}
    </NavButtonGroup>
    <NavButtonGroup>
      {/* Library */}
      <NavButton data-id="nav_library" icon={Icon.GRID} route={LIBRARY_ROUTE} title="Library" />
      {/* Security Settings */}
      <NavButton data-id="nav_security_settings" icon={Icon.LOCK} route={SECURITY_SETTINGS_ROUTE} title="Security" />
      <NavButton data-id="nav_plugins" icon={Icon.PLAY} route={PLUGINS_ROUTE} title="Plugins" />
      {/* Settings */}
      <NavButton data-id="nav_settings" icon={Icon.SETTINGS} route={SETTINGS_ROUTE} title="Settings" />
      {/* Support */}
      <NavButton data-id="nav_support" icon={Icon.SUPPORT} route={SUPPORT_ROUTE} title="FAQs" />
      {/* Profile */}
      <ProfileButton />
    </NavButtonGroup>
  </NavContainer>;
export default React.memo(Nav);