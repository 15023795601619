import { assertUnreachable, Integration } from '@kindo/universal';
import { ShareWorkflowModal } from '../modals';
import DeleteIntegrationModal from '../modals/DeleteIntegrationModal';
import DeleteWorkflowModal from '../modals/DeleteWorkflowModal';
import ShareChatModal from '../modals/ShareChatModal';
import { DlpFiltersConfigModal } from '../SecuritySettings/OrgSecurityControls/DlpFiltersConfig/DlpFiltersConfigModal';
import { PaywallModal } from '../shared';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
const ActiveModal: React.FC = () => {
  // Redux
  const {
    activeModal
  } = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };
  if (!activeModal) return null;
  const {
    type
  } = activeModal;
  switch (type) {
    case Modal.CONFIGURE_DLP_FILTERS:
      return <DlpFiltersConfigModal canAccessSecuritySettings={activeModal.canAccessSecuritySettings} modelOrProvider={activeModal.modelOrProvider} onClose={closeModal} />;
    case Modal.DELETE_INTEGRATION:
      return <DeleteIntegrationModal integration={activeModal.integrationName as Integration} integrationId={activeModal.integrationId} onClose={closeModal} onSuccess={activeModal.onSuccess} />;
    case Modal.DELETE_WORKFLOW:
      return <DeleteWorkflowModal onClose={closeModal} redirectRoute={activeModal.redirectRoute} workflowId={activeModal.workflowId} workflowTitle={activeModal.workflowTitle} />;
    case Modal.SHARE_WORKFLOW:
      return <ShareWorkflowModal onClose={closeModal} workflowId={activeModal.workflowId} />;
    case Modal.SHARE_CHAT:
      return <ShareChatModal chatId={activeModal.chatId} onClose={closeModal} />;
    case Modal.PAYWALL:
      return <PaywallModal onClose={closeModal} payload={activeModal} />;
    default:
      // Ensures exhaustiveness of switch
      assertUnreachable(type);
      return null;
  }
};
export default ActiveModal;