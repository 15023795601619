import clsx from 'clsx';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { tv, type VariantProps } from 'tailwind-variants';
const Paginate = ReactPaginate as unknown as React.FC<ReactPaginateProps>;
const style = tv({
  base: 'text-blue-ish',
  variants: {}
});
interface PaginationProps extends VariantProps<typeof style> {
  initialPage: number;
  onPageActive: (page: number) => void;
  onPageChange: (page: number) => void;
  totalPages: number;
  className?: string;
}
export const Pagination = ({
  className,
  initialPage,
  totalPages,
  onPageActive,
  onPageChange
}: PaginationProps) => {
  const btnClassName = 'py-2 px-3 text-sm';
  if (totalPages <= 1) return null;
  return <div className={style({
    className
  })}>
      <Paginate breakLabel="..." nextLabel={initialPage + 1 < totalPages ? 'Next' : null} onPageChange={(item: any) => onPageChange(item.selected)} onPageActive={(item: any) => onPageActive(item.selected)} initialPage={initialPage} pageCount={totalPages} previousLabel={initialPage !== 0 ? 'Previous' : null} renderOnZeroPageCount={null} breakClassName={btnClassName} pageClassName="flex" pageLinkClassName={clsx(btnClassName, 'hover:bg-blue-navy hover:text-white rounded-full')} activeLinkClassName="bg-blue-navy text-white" previousClassName="flex" previousLinkClassName={btnClassName} nextClassName="flex" nextLinkClassName={btnClassName} containerClassName="flex flex-row grow space-x-2 justify-center" />
    </div>;
};
export default Pagination;