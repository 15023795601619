import _styled from "styled-components";
import { CustomDlpFilterType, DlpFilterPolicy, insertIf, isValidRegex } from '@kindo/universal';
import { useState } from 'react';
import { CustomDlpFilter } from '../DlpFilters.types';
import { Button, ButtonType, Color, Icon, IconButton, IconButtonType, Select, Table, TableColumn, TextField, TextFieldType } from '~/components/core';
const CustomPrivacyFiltersContainer = _styled.div.withConfig({
  displayName: "CustomDlpFilters__CustomPrivacyFiltersContainer",
  componentId: "sc-1488u25-0"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1rem"
});
const NewFilterTypeAndInput = _styled.div.withConfig({
  displayName: "CustomDlpFilters__NewFilterTypeAndInput",
  componentId: "sc-1488u25-1"
})({
  "display": "flex",
  "width": "70%",
  "maxWidth": "32rem",
  "gap": "0.5rem"
});
const CreateNewFilterContainer = _styled.div.withConfig({
  displayName: "CustomDlpFilters__CreateNewFilterContainer",
  componentId: "sc-1488u25-2"
})({
  "display": "flex",
  "gap": "1rem"
});
interface CustomDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  createCustomDlpFilter: (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => void;
  customDlpFilters: CustomDlpFilter[];
  deleteCustomDlpFilter: (value: string) => void;
}
const CustomDlpFilters: React.FC<CustomDlpFiltersProps> = ({
  canAccessSecuritySettings,
  deleteCustomDlpFilter,
  customDlpFilters,
  createCustomDlpFilter
}) => {
  // State
  const [newFilterInputValue, setNewFilterInputValue] = useState<string>('');
  const [newFilterInputError, setNewFilterInputError] = useState<string>('');
  const [newFilterType, setNewFilterType] = useState<CustomDlpFilterType>(CustomDlpFilterType.LITERAL);
  const newFilterPlaceholder = newFilterType === CustomDlpFilterType.LITERAL ? 'Private phrase' : 'Private.*phrase';
  const createNewFilter = () => {
    const trimmedInput = newFilterInputValue.trim();
    if (!trimmedInput) {
      setNewFilterInputError('Filter cannot be empty.');
      return;
    }

    // Validate regex
    if (newFilterType === CustomDlpFilterType.REGEX && !isValidRegex(trimmedInput)) {
      setNewFilterInputError('Invalid regex entered.');
    }
    createCustomDlpFilter(DlpFilterPolicy.REDACT, newFilterType, trimmedInput);
  };
  return <CustomPrivacyFiltersContainer>
      {canAccessSecuritySettings && <CreateNewFilterContainer>
          <NewFilterTypeAndInput>
            <Select onChange={value => setNewFilterType(value as CustomDlpFilterType)} options={[{
          label: 'Phrase',
          value: CustomDlpFilterType.LITERAL
        }, {
          label: 'Regex',
          value: CustomDlpFilterType.REGEX
        }]} placeholderLabel="Type" value={newFilterType} />
            <TextField error={!!newFilterInputError} errorMessage={newFilterInputError} fullWidth onChange={value => {
          setNewFilterInputError('');
          setNewFilterInputValue(value);
        }} onEnter={createNewFilter} placeholder={newFilterPlaceholder} type={TextFieldType.OUTLINED} value={newFilterInputValue} />
          </NewFilterTypeAndInput>

          <Button label="Create" onClick={createNewFilter} type={ButtonType.FILLED} />
        </CreateNewFilterContainer>}
      <Table columns={[{
      title: 'Value'
    }, {
      title: 'Type',
      width: {
        "width": "16.666667%"
      }
    }, ...insertIf<TableColumn>(canAccessSecuritySettings, {
      title: '',
      width: {
        "width": "16.666667%"
      }
    })]} noRowsText="No custom filters created." rows={customDlpFilters.map(customDlpFilter => ({
      key: customDlpFilter.value,
      cells: [customDlpFilter.value, customDlpFilter.type, ...insertIf(canAccessSecuritySettings, <IconButton color={Color.GRAY} icon={Icon.CLOSE} onClick={() => deleteCustomDlpFilter(customDlpFilter.value)} type={IconButtonType.SIMPLE} />)]
    }))} />
    </CustomPrivacyFiltersContainer>;
};
export default CustomDlpFilters;