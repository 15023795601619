import _styled from "styled-components";
import { Color, Typography, TypographySize } from '../core';
const SettingsSectionContainer = _styled.div.withConfig({
  displayName: "SettingsSection__SettingsSectionContainer",
  componentId: "sc-1w1v21s-0"
})({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1rem"
});
const HeaderRow = _styled.div.withConfig({
  displayName: "SettingsSection__HeaderRow",
  componentId: "sc-1w1v21s-1"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between"
});
const TitleAndDescription = _styled.div.withConfig({
  displayName: "SettingsSection__TitleAndDescription",
  componentId: "sc-1w1v21s-2"
})({
  "display": "flex",
  "flexDirection": "column"
});
interface SettingsSectionProps {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  description?: string;
  headerEndElement?: React.ReactNode;
}
const SettingsSection = ({
  title,
  children,
  description,
  headerEndElement
}: SettingsSectionProps) => <SettingsSectionContainer>
    <HeaderRow>
      <TitleAndDescription>
        <Typography color={Color.NAVY} size={TypographySize.H3}>
          {title}
        </Typography>
        {description && <Typography color={Color.NAVY}>{description}</Typography>}
      </TitleAndDescription>
      {headerEndElement}
    </HeaderRow>
    {children}
  </SettingsSectionContainer>;
export default SettingsSection;