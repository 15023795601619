import { DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { trpc } from '~/trpc';
export type CustomDlpFilter = Awaited<ReturnType<(typeof trpc)['customDlpFilters']['listOrgBaseCustomDlpFilters']['query']>>['items'][number];
export type DlpFiltersConfig = Record<DlpFilter, DlpFilterPolicy>;
type DlpFiltersConfigResponse = Awaited<ReturnType<(typeof trpc)['dlpFiltersConfig']['getOrgBaseDlpFiltersConfig']['query']>>;

// Transforms the API response to just the config values,
// removing orgId and other unwanted fields
export const transformDlpFiltersConfigResponse = (data: DlpFiltersConfigResponse): DlpFiltersConfig => ({
  // TODO: Fix casts by typechecking strings to enum in the get endpoint
  creditCard: data.creditCard as DlpFilterPolicy,
  crypto: data.crypto as DlpFilterPolicy,
  dateTime: data.dateTime as DlpFilterPolicy,
  emailAddress: data.emailAddress as DlpFilterPolicy,
  ibanCode: data.ibanCode as DlpFilterPolicy,
  ipAddress: data.ipAddress as DlpFilterPolicy,
  nrp: data.nrp as DlpFilterPolicy,
  location: data.location as DlpFilterPolicy,
  person: data.person as DlpFilterPolicy,
  phoneNumber: data.phoneNumber as DlpFilterPolicy,
  medicalLicense: data.medicalLicense as DlpFilterPolicy,
  url: data.url as DlpFilterPolicy,
  usBankNumber: data.usBankNumber as DlpFilterPolicy,
  usDriverLicense: data.usDriverLicense as DlpFilterPolicy,
  usITIN: data.usITIN as DlpFilterPolicy,
  usPassport: data.usPassport as DlpFilterPolicy,
  usSSN: data.usSSN as DlpFilterPolicy
});