import { DLP_FILTER_DISPLAY_NAMES, DlpFilter, DlpFilterPolicy, typedEntries } from '@kindo/universal';
import { Table, Toggle } from '~/components/core';
interface StandardDlpFiltersProps {
  canAccessSecuritySettings: boolean;
  dlpFiltersConfig: Record<DlpFilter, DlpFilterPolicy> | undefined;
  dlpFiltersConfigIsLoading: boolean;
  updateStandardDlpFilterConfig: (dlpFilter: DlpFilter, newPolicy: DlpFilterPolicy) => void;
  updatingFilters: DlpFilter[];
}
const StandardDlpFilters: React.FC<StandardDlpFiltersProps> = ({
  dlpFiltersConfig,
  updateStandardDlpFilterConfig,
  dlpFiltersConfigIsLoading,
  canAccessSecuritySettings,
  updatingFilters
}) => <Table columns={[{
  title: 'Name'
}, {
  title: 'Redact',
  width: {
    "width": "16.666667%"
  }
}]} loading={dlpFiltersConfigIsLoading} noRowsText="No data loss prevention controls are available at this time." rows={dlpFiltersConfig ? typedEntries(dlpFiltersConfig).map(([optionName, policy]) => ({
  key: optionName,
  cells: [DLP_FILTER_DISPLAY_NAMES[optionName], <Toggle key={optionName} checked={policy === DlpFilterPolicy.REDACT} disabled={!canAccessSecuritySettings} loading={updatingFilters.includes(optionName)} onToggle={(isChecked: boolean) => updateStandardDlpFilterConfig(optionName, isChecked ? DlpFilterPolicy.REDACT : DlpFilterPolicy.PASSTHROUGH)} />]
})) : []} />;
export default StandardDlpFilters;