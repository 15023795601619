import _styled from "styled-components";
import { WorkflowRole } from '@kindo/universal';
import { Color, Select, SelectOption, Typography, TypographySize, TypographyWeight } from '~/components/core';
const WorkflowUserPermissionRowContainer = _styled.div.withConfig({
  displayName: "WorkflowUserPermissionRow__WorkflowUserPermissionRowContainer",
  componentId: "sc-2me4l2-0"
})({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderBottomWidth": "0.063rem",
  "paddingTop": "0.75rem",
  "paddingBottom": "0.75rem"
});
const NameAndEmail = _styled.div.withConfig({
  displayName: "WorkflowUserPermissionRow__NameAndEmail",
  componentId: "sc-2me4l2-1"
})({
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center"
});
interface WorkflowUserPermissionRowProps {
  disabled: boolean;
  onChange: (permission: WorkflowRole | null) => void;
  options: SelectOption<WorkflowRole | null>[];
  permission: WorkflowRole;
  userEmail: string;
  userName: string | null;
}
const WorkflowUserPermissionRow: React.FC<WorkflowUserPermissionRowProps> = ({
  disabled,
  onChange,
  options,
  permission,
  userEmail,
  userName
}) => <WorkflowUserPermissionRowContainer>
    <NameAndEmail>
      <Typography size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
        {userName ?? userEmail}
      </Typography>
      {!!userName && userName !== userEmail && <Typography color={Color.GRAY} size={TypographySize.X_SMALL}>
          {userEmail}
        </Typography>}
    </NameAndEmail>

    <Select<WorkflowRole | null> disabled={disabled} onChange={value => onChange(value)} options={options} placeholderLabel="Select Access Level" value={permission} />
  </WorkflowUserPermissionRowContainer>;
export default WorkflowUserPermissionRow;