import { INVITE_ID_QUERY_PARAM } from '@kindo/universal';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';

import useToast, { ToastType } from './useToast';
import { useURLParams } from './useUrlParams';

import { nextTrpc } from '~/trpc';

/**
 * This hook checks for an inviteId in the URL,
 * and accepts the invite if it exists.
 */
const useAcceptOrgInvite = () => {
  // Custom hooks
  const { enqueueToast } = useToast();
  const router = useRouter();

  const params = useURLParams<{
    [INVITE_ID_QUERY_PARAM]?: string;
  }>();

  const { [INVITE_ID_QUERY_PARAM]: inviteId } = params;

  const clearInviteId = () => {
    const routeWithoutInviteId: NextRouter = {
      ...router,
      query: {
        ...router.query,
        [INVITE_ID_QUERY_PARAM]: undefined
      }
    };
    void router.replace(routeWithoutInviteId, undefined, {
      shallow: true
    });
  };

  // Mutation
  const acceptInviteMutation = nextTrpc.orgs.acceptInvite.useMutation({
    onSuccess: () => {
      enqueueToast({
        message: 'Organization invite accepted.',
        type: ToastType.SUCCESS
      });
    },
    onError: (error) => {
      console.error(error);
      enqueueToast({
        message: error.data?.kindoErrorMessage || 'Failed to accept invite.',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      clearInviteId();
    }
  });

  // Handler for accepting an invite
  const acceptInvite = () => {
    if (!inviteId) {
      return;
    }

    acceptInviteMutation.mutate({ inviteId });
  };

  // useEffect that checks for an inviteId in the URL
  useEffect(() => {
    acceptInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId]);
};

export default useAcceptOrgInvite;
